<template>
  <div>
    <div v-if="rationLoading" class="d-flex align-items-center">
      <strong>{{ $t("loading") }}</strong>
      <b-spinner class="ml-auto" />
    </div>
    <div v-if="!rationLoading">
      <b-card>
        <!-- Media -->
        <div class="d-flex justify-content-between flex-column flex-md-row">
          <b-media class="mb-2">
            {{ $t("rationName") }}:
            <h4 class="mb-1">
              {{ rationData.rasyonadi }}
            </h4>
            <div>
              {{ $t("rationType") }}:
              <h4>
                {{ $t(rationData.tip.toLowerCase()) }}
                {{ $t("rationWSuffix") }}
              </h4>
            </div>
            {{ $t("addUser") }}:

            <h4>
              {{ rationData.addUsername }}
            </h4>
          </b-media>
          <h3>
            {{
              dayjs(rationData.tarih)
                .locale(`${$i18n.locale}`)
                .format("DD MMMM YYYY HH:mm")
            }}
          </h3>
          <!-- <div class="ml-0 ml-md-2">
            <b-button
              v-b-modal.modalPrint
              variant="outline-primary"
              size="sm"
              class="mr-2"
            >
              <feather-icon icon="PrinterIcon" />
              {{ $t("print") }}
            </b-button>
          </div> -->
        </div>
        <!-- User Info: Input Fields -->
        <b-row>
          <b-col cols="4" xs="2" sm="3" md="2" lg="2">
            <b-form-group :label="$t('solvePrice')" label-for="price">
              <h4 id="price" class="">
                {{
                  rationData.price == null
                    ? "0"
                    : parseFloat(rationData.price)
                        .toFixed(getUserData().pricedecimal)
                        .replace(".", ",")
                }}
                {{ getUserData().currency }}
              </h4>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Action Buttons -->
      </b-card>
      <b-card class="mt-1">
        <slot />
      </b-card>
    </div>
    <b-modal
      id="modalPrint"
      centered
      :title="$t('printOptions')"
      size="xl"
      hide-footer
      class="modal-lg"
      :ok-title="$t('ok')"
      ok-only
      @ok="$refs.submitButtonRef.click()"
    >
      <print-modal :ration-data="rationData" />
    </b-modal>
  </div>
</template>

<script>
import { BMedia, BRow, BCol, BFormGroup, BCard, BSpinner } from "bootstrap-vue";
import { getUserData } from "@/auth/utils";
import dayjs from "dayjs";
import tr from "dayjs/locale/tr";
import az from "dayjs/locale/az";
import ru from "dayjs/locale/ru";
import PrintModal from "./Components/ProductionPrintModal.vue";

export default {
  components: {
    BMedia,

    BRow,
    BCol,
    BFormGroup,
    BCard,
    BSpinner,
    PrintModal,
  },
  props: {
    rationData: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      getUserData: getUserData,
      dayjs: dayjs,
      tr: tr,
      az: az,
      ru: ru,
    };
  },
  computed: {
    rationLoading() {
      return this.$store.state.rationsModule.rationLoading;
    },
  },

  methods: {
    async deleteRation() {
      await this.$store.dispatch("rationsModule/deleteRation", {
        rationid: this.rationData.id,
        plantid: this.$store.state.app.selectedPlantId,
        tur: this.$route.params.tur,
      });
      this.$router.back();
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.m-small {
  margin-top: 0.2rem;
  @media screen and (max-width: 768px) {
    margin-left: 0.5rem;
    font-size: 1rem;
    font-weight: bold;
  }
}
.bg-yellow {
  background-color: #fff820 !important;
  color: #5e5874 !important;
}
</style>
