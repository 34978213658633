<template>
  <b-table ref="hammaddeTable" :items="items" :fields="fields" responsive>
    <!-- Example scoped slot for select state illustrative purposes -->
    <template #cell(selected)="{ rowSelected }">
      <template v-if="rowSelected">
        <feather-icon icon="DiscIcon" />
      </template>

      <template v-else>
        <feather-icon icon="CircleIcon" />
      </template>
    </template>

    <template #cell(avatar)="data">
      <b-avatar :src="data.value" />
    </template>
  </b-table>
</template>

<script>
import { BTable, BAvatar } from "bootstrap-vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import { getUserData } from "@/auth/utils";
export default {
  components: {
    BTable,

    BAvatar,
    FeatherIcon,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      // selectedCheck: false,
      fields: [
        // { key: "kod", label: this.$t("code") },
        {
          key: "ingredientname",
          label: this.$t("ingredientName"),
        },
        // {
        //   key: "fiyat",
        //   label: this.$t("price"),
        //   formatter: (val) =>
        //     parseFloat(val).toFixed(getUserData().pricedecimal) +
        //     " " +
        //     getUserData().currency,
        //   tdClass: "text-right mw-300",
        // },
        {
          key: "karisim",
          label: this.$t("mixValue") + "(Kg)",
          tdClass: "text-right mw-300",
          formatter: (val) =>
            parseFloat(val).toFixed(getUserData().ingredientdecimal),
        },
      ],

      selected: [],
    };
  },
};
</script>

<style lang="scss">
.b-table-selectable {
  .feather {
    font-size: 1.3rem;
  }
}
</style>
