<template>
  <div>
    <component :is="'b-card'" v-if="rationData.hammaddeler == null">
      <h4 class="alert-heading">
        {{ $t("xFetchError", { value: $t("ration") }) }}
      </h4>
      <div class="alert-body">
        {{ $t("xNotFound", { value: $t("ration") }) }}
        <b-link class="alert-link" :to="{ name: 'plants' }" />
      </div>
    </component>
    <div v-if="rationData.hammaddeler != null">
      <production-ration-view-info
        :key="rationData.id"
        :ration-data="rationData"
        class="mt-2 pt-75"
        :ration-view="rationView"
      >
        <b-row class="mt-1">
          <b-col>
            <hammadde-table :items="rationData.hammaddeler" />
          </b-col>
          <b-col>
            <besin-maddesi-table
              :items="
                rationData.besinmaddeleri.map((x) => ({
                  besinmaddesi: x.nutrientname,

                  ...x,
                }))
              "
            />
          </b-col>
        </b-row>
      </production-ration-view-info>
    </div>
  </div>
</template>

<script>
import { BRow, BCol, BCard, BLink } from "bootstrap-vue";
import router from "@/router";
import store from "@/store";
import ProductionRationViewInfo from "./ProductionRationViewInfo.vue";

import VueI18n from "@/libs/i18n";
import HammaddeTable from "./Components/HammaddeTable.vue";
import BesinMaddesiTable from "./Components/BesinMaddesiTable.vue";

export default {
  components: {
    BCard,
    BLink,
    ProductionRationViewInfo,
    HammaddeTable,
    BesinMaddesiTable,
    BRow,
    BCol,
  },

  data: function () {
    return {
      rationView: {
        count: 0,
        caGrid: false,
        gaGrid: false,
        svGrid: false,
        syGrid: false,
        kacgunoncedogurduGrid: false,
        laktasyonGrid: false,
        minkabalar: false,
        dayspregnantGrid: false,
        buzagiagirligiGrid: false,
        kacgundursagiliyorGrid: false,
        buzagiyasiGrid: false,
        gebeliksuresiGrid: false,
        conditionscoreGrid: false,
        toplamgrupsayisiGrid: false,
        dogumakalangunGrid: false,
        rasyongunGrid: false,
        karkasrandimaniGrid: false,
      },
    };
  },
  computed: {
    rationData() {
      return store.state.rationsModule.selectedProductionRation;
    },
  },
  beforeRouteEnter(to, from, next) {
    if (to.name == "productionRationView") {
      to.meta.breadcrumb = [];
      to.meta.breadcrumb.push({
        to: "/rations",
        text: VueI18n.t(`plants`),
        active: true,
      });
      to.meta.breadcrumb.push({
        text: VueI18n.t(`productionRations`),
        to: `/production-rations`,
        active: false,
      });
      to.meta.breadcrumb.push({
        text: VueI18n.t("ration"),
        active: true,
      });
    }
    next();
  },
  async mounted() {
    await store.dispatch("rationsModule/fetchProductionRation", {
      id: router.currentRoute.params.rationid,
      tur: router.currentRoute.params.tur,
      plantid: store.state.app.selectedPlantId,
    });

    // await store.dispatch("rationsModule/getRationIngredients");
    this.rationTypeView();
  },
  methods: {
    async rationTypeView() {},
  },
};
</script>

<style></style>
