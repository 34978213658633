<template>
  <div>
    <!-- Action -->
    <b-row>
      <b-col cols="12" sm="4">
        <h4>{{ $t("printRation") }}</h4>
      </b-col>
      <b-col cols="12" sm="4" class="d-flex justify-content-end ml-auto">
        <b-button
          class="d-flex align-items-center"
          variant="outline-primary"
          size="sm"
          @click="printRation()"
        >
          <feather-icon icon="PrinterIcon" size="20" />
          <span class="m-small ml-1">{{ $t("print") }}</span>
        </b-button>
      </b-col>
      <b-col v-if="false" cols="12" sm="4" class="ml-auto">
        <b-button
          class="d-flex align-items-center"
          variant="outline-primary"
          size="sm"
          @click="previewPdf()"
        >
          <feather-icon icon="EyeIcon" size="20" />
          <span class="m-small ml-1">{{ $t("preview") }}</span>
        </b-button>
      </b-col>
    </b-row>
    <!-- Info -->
    <b-row class="mt-2">
      <b-col cols="6" sm="3">
        <h6>
          {{ $t("mixTotal") }}
        </h6>
        <span>
          {{
            (rationData.yemToplami
              ? rationData.yemToplami
              : rationData.hammaddeler.reduce((x, b) => x + b.karisim, 0)
            ).toFixed(ingredientDecimal)
          }}
        </span>
      </b-col>
      <b-col cols="6" sm="3">
        <h6>{{ $t("price") }} ({{ currency }}/Ton)</h6>
        <div
          class="d-flex"
          :class="symbolInFront && 'flex-row-reverse justify-content-end'"
        >
          <span>
            {{
              rationDat.rasyonfiyati
                ? rationDat.rasyonfiyati.toFixed(priceDecimal)
                : rationData.price.toFixed(priceDecimal)
            }}
          </span>

          <span>
            &nbsp;
            {{ currency }}</span
          >
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-1">
      <b-col cols="12" sm="6">
        <!-- Report Type -->
        <b-form-group v-slot="{ ariaDescribedby }" :label="$t('reportType')">
          <b-form-radio-group
            id="type-group"
            v-model="printOptions.type"
            :options="typeOption"
            :aria-describedby="ariaDescribedby"
            name="type-group"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" sm="6">
        <!-- Report Ext -->
        <b-form-group v-slot="{ ariaDescribedby }" :label="$t('reportExt')">
          <b-form-radio-group
            id="ext-group"
            v-model="printOptions.ext"
            :options="extOption"
            :aria-describedby="ariaDescribedby"
            name="ext-group"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <!-- Detailed Options -->
    <b-row v-if="printOptions.type == 'None'" class="px-1">
      <b-col cols="12" class="detail">
        <b-form-group
          v-slot="{ ariaDescribedby }"
          :label="$t('detailedOptions')"
        >
          <b-form-checkbox-group
            id="detailed-group"
            v-model="printOptions.detail"
            :options="detailOption"
            :aria-describedby="ariaDescribedby"
            name="detailed-group"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <!-- Notes -->
    <b-row class="mt-1">
      <b-col cols="12" sm="6">
        <b-form-input
          v-model="printOptions.firstNote"
          :placeholder="$t('firstNote')"
        />
      </b-col>
      <b-col class="mt-1 mt-sm-0">
        <b-form-input
          v-model="printOptions.secondNote"
          :placeholder="$t('secondNote')"
        />
      </b-col>
    </b-row>
    <!-- Table -->
    <b-row class="mt-1">
      <b-col cols="12" md="6">
        <b-table
          striped
          class="smaller-text"
          :fields="hammaddeTableFields"
          responsive=""
          small
          :items="rationData.hammaddeler"
        />
      </b-col>
      <b-col cols="12" md="6">
        <b-table
          striped
          class="smaller-text"
          responsive=""
          :fields="besinmaddeTableFields"
          small
          :items="rationData.besinmaddeleri"
        >
          <template #cell(print)="{ item }">
            <b-form-checkbox v-model="item.print" />
          </template>
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormCheckboxGroup,
  BButton,
  BFormCheckbox,
  BFormRadioGroup,
  BFormInput,
  BTable,
} from "bootstrap-vue";
import { getUserData } from "@/auth/utils";
export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormCheckbox,
    BButton,
    BFormCheckboxGroup,
    BFormRadioGroup,
    BFormInput,
    BTable,
  },
  props: {
    rationData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      blob: null,
      printOptions: {
        type: "Uretim",
        ext: "PDF",
        detail: [
          { showprice: true },
          { showingredientprices: true },
          { showingminmax: true },
          { shownutminmax: true },
        ],
      },
      typeOption: [
        { text: this.$t("feedProdRecipe"), value: "Uretim" },
        { text: this.$t("detailedReport"), value: "Detay" },
        { text: this.$t("analyzeCertificate"), value: "Analiz" },
      ],
      extOption: [
        { text: ".pdf", value: "PDF" },
        { text: ".xlsx (Excel)", value: "EXCEL" },
        // { text: ".docx (Word)", value: "WORD" },
      ],
      detailOption: [
        {
          text: this.$t("printFeedUnitCost"),
          value: {
            showprice: true,
          },
        },
        {
          text: this.$t("printIngPrice"),
          value: { showingredientprices: true },
        },
        {
          text: this.$t("printIngMinMax"),
          value: {
            showingminmax: true,
          },
        },
        { text: this.$t("printNutMinMax"), value: { shownutminmax: true } },
        { text: this.$t("printNotUsedIng"), value: { showunused: true } },
      ],
      hammaddeTableFields: [
        { key: "kod", label: this.$t("code") },
        { key: "ingredientname", label: this.$t("ingredientName") },
        {
          key: "karisim",
          label: this.$t("mixValue") + "(Kg)",
          tdClass: "text-right bg-success text-white",
          formatter: (val) =>
            ((val * this.selectedPlantTonaj) / 1000).toFixed(
              this.ingredientDecimal
            ),
        },

        {
          key: "fiyat",
          label: this.$t("price"),
          tdClass: "text-right",
          formatter: (val) => val.toFixed(this.priceDecimal) + this.currency,
        },
      ],
      besinmaddeTableFields: [
        { key: "print", label: this.$t("print") },
        { key: "nutrientname", label: this.$t("nutrientName") },
        { key: "birimadi", label: this.$t("unit") },
        { key: "kod", label: this.$t("code") },
        {
          key: "deger",
          label: this.$t("value"),
          tdClass: "text-right bg-success text-white",
          formatter: (val) => val.toFixed(this.nutrientDecimal),
        },
      ],
    };
  },
  computed: {
    priceDecimal() {
      return getUserData().pricedecimal;
    },
    ingredientDecimal() {
      return getUserData().ingredientdecimal;
    },
    nutrientDecimal() {
      return getUserData().nutrientdecimal;
    },
    currency() {
      return getUserData().currency;
    },
    symbolInFront() {
      return getUserData().symbolinfront;
    },
    selectedPlantTonaj() {
      const selectedId = this.$store.state.app.selectedPlantId;
      if (this.$store.state.plantsModule.plantData.length > 0) {
        return (
          this.$store.state.plantsModule.plantData.filter(
            (x) => x.id == selectedId
          )[0]?.tonaj ?? this.$store.state.plantsModule.plantData[0]?.tonaj
        );
      } else return 1000;
    },
    plantName() {
      const selectedId = this.$store.state.app.selectedPlantId;
      if (this.$store.state.plantsModule.plantData.length > 0) {
        return (
          this.$store.state.plantsModule.plantData.filter(
            (x) => x.id == selectedId
          )[0]?.plantname ??
          this.$store.state.plantsModule.plantData[0]?.plantname
        );
      } else return "";
    },
  },
  mounted() {
    this.rationData.besinmaddeleri = this.rationData.besinmaddeleri.map(
      (item) => {
        item.print = true;
        return item;
      }
    );
  },
  methods: {
    async previewPdf() {
      if (this.blob) {
        var URL = window.URL || window.webkitURL;
        var downloadUrl = URL.createObjectURL(this.blob);
        const link = document.createElement("a");
        link.href = downloadUrl;

        window.open(downloadUrl);
      }
    },
    async printRation() {
      const localRationData = { ...this.rationData };
      await setTimeout(async () => {
        const ration = {
          ...localRationData,
          rasyon: [
            {
              price: 0,
            },
          ],
        };
        ratio = {
          id: localRationDat.id,
          companyname: getUserData().companyname,
          plantname: this.plantName,
          kaynak: localRationDat.kaynak,
          code: localRationDat.code,
          rationname: localRationDat.rasyonadi,
          rasyonfiyati: localRationDat.rasyonfiyati ?? 0,
          tarih: localRationDat.tarih,
          price: localRationDat.fiyat ?? localRationData.price,
          raportipi: this.printOptions.type,
          raporcikti: this.printOptions.ext,
          not1: this.printOptions.firstNote ?? "",
          not2: this.printOptions.secondNote ?? "",
          tonaj: this.selectedPlantTonaj.toString(),
          currency: this.currency,
          showcompanylogo: getUserData().showcompanylogoinreports,
          showcompanyname: getUserData().showcompanynameinreports,
          rasyonname: localRationDat.rasyonadi,
          imageadi: "false",
          imageinvisible: "true",
        };

        if (this.printOptions.type == "Detay") {
          const keys = [
            "showprice",
            "showingredientprices",
            "showingminmax",
            "shownutminmax",
            "showunused",
          ];
          // İstenilen formata uygun hala getiriyorum
          this.printOptions.detail.map((eachDetail) => {
            return Object.entries(eachDetail).map((val) => {
              const key = val[0];
              const value = val[1];
              ratio[key] = value;
            });
          });
          // Eksik keyleri ekliyorum
          keys.map((key) => {
            if (!Object.keys(ratio).includes(key)) {
              ratio[key] = false;
            }
          });
        }
        ration.besinmaddeleri = ration.besinmaddeleri.filter(
          (item) => item.print
        );
        this.blob = await this.$store.dispatch(
          "rationsModule/printProdRation",
          ration
        );
      }, 300);
    },
  },
};
</script>

<style>
.smaller-text {
  font-size: 0.9rem;
}
#type-group,
#ext-group,
.detail {
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  padding-top: 10px;
}
#type-group,
#ext-group {
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}
</style>
